// flyerSection.scss
@import 'variables';

.flyer-section {
  padding: 80px 0;
  background-color: $background-color;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .flyer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  .flyer-image,
  .flyer-video {
    width: 400px; // Set a specific width for both flyer image and video
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
      width: 100%; // Make the flyer image and video full width on mobile
    }
  }
}
