// applyNow.scss
@import 'variables';

.apply-now {
  padding: 100px 0;
  background-color: $background-color;

  .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 20px;
  }

  h1 {
    font-size: 36px;
    margin-bottom: 20px;
    color: $text-color;
    text-align: center;
  }

  .instructions {
    font-size: 18px;
    margin-bottom: 40px;
    color: $text-color;
    text-align: center;
  }

  .apply-now-content {
    background-color: $accent-color;
    padding: 40px;
    border-radius: 8px;

    form {
      .form-group {
        margin-bottom: 20px;

        label {
          display: block;
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 10px;
          color: $text-color;
        }

        input,
        textarea {
          width: 100%;
          padding: 12px;
          font-size: 16px;
          border: none;
          border-radius: 4px;
          background-color: $background-color;
          color: $text-color;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          transition: box-shadow 0.3s ease;

          &:focus {
            outline: none;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          }
        }

        .checkbox-group {
          display: flex;
          gap: 20px;
          flex-wrap: wrap;

          label {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: normal;
            margin-bottom: 10px;

            input[type="checkbox"] {
              margin-right: 8px;
            }
          }
        }

        .radio-group {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;

          &.multiple-options {
            flex-direction: column;
            align-items: flex-start;
          }

          label {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: normal;
            margin-bottom: 10px;

            input[type="radio"] {
              margin-right: 8px;
              appearance: none;
              -webkit-appearance: none;
              border: 2px solid $text-color;
              border-radius: 50%;
              width: 16px;
              height: 16px;
              outline: none;

              &:checked {
                background-color: $primary-color;
                border-color: $primary-color;
              }
            }
          }
        }
      }

      button {
        display: block;
        width: 100%;
        padding: 12px;
        font-size: 18px;
        font-weight: bold;
        background-color: $primary-color;
        color: $background-color;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: darken($primary-color, 10%);
        }
      }
    }
  .success-message,
    .error-message {
      margin-top: 20px;
      font-weight: bold;
    }

    .success-message {
      color: green;
    }

    .error-message {
      color: red;
    }
  }
}
