// contact.scss
@import 'variables';

.contact {
  padding: 100px 0;
  background-color: $background-color;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }

  h1 {
    font-size: 36px;
    margin-bottom: 40px;
    color: $text-color;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 28px;
      margin-bottom: 30px;
    }
  }

  .contact-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: $accent-color;
    border-radius: 8px;
    padding: 40px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
      flex-direction: column;
      padding: 30px;
    }

    .contact-form {
      flex: 1;
      padding-right: 50px;

      @media (max-width: 768px) {
        padding-right: 0;
        margin-bottom: 40px;
      }

      form {
        .form-group {
          margin-bottom: 20px;

          input,
          textarea {
            width: 100%;
            padding: 12px;
            font-size: 16px;
            border: none;
            border-radius: 4px;
            background-color: $background-color;
            color: $text-color;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            transition: box-shadow 0.3s ease;

            &:focus {
              outline: none;
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            }

            &::placeholder {
              color: lighten($text-color, 30%);
            }
          }

          textarea {
            height: 150px;
          }
        }

        button {
          padding: 12px 30px;
          font-size: 18px;
          font-weight: bold;
          background-color: $primary-color;
          color: $background-color;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: darken($primary-color, 10%);
          }
        }
      }
 .success-message,
      .error-message {
        margin-top: 20px;
        font-weight: bold;
      }

      .success-message {
        color: green;
      }

      .error-message {
        color: red;
      }
    }

    .contact-info {
      flex: 1;

      @media (max-width: 768px) {
        text-align: center;
      }

      h3 {
        font-size: 24px;
        margin-bottom: 20px;
        color: $text-color;
      }

      p {
        font-size: 18px;
        margin-bottom: 20px;
        color: $text-color;
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          margin-bottom: 10px;
          font-size: 16px;
          color: $text-color;

          i {
            margin-right: 10px;
            color: $primary-color;
          }
        }
      }
    }
  }
}
