// header.scss
@import 'variables';
@import 'mixins';
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap');

.header {
  background-color: $background-color;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .logo-wrapper {
    display: flex;
    align-items: center;
  }

  .logo {
    display: flex;
    align-items: center;
    text-decoration: none;

    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }

  .title-wrapper {
    margin-left: 20px;

    .title {
      font-family: 'Lora', serif;
      font-size: 24px;
      font-weight: 700;
      color: $text-color;
      margin: 0;
    }

    .subtitle {
      font-family: 'Lora', serif;
      font-size: 16px;
      color: $text-color;
      margin: 0;
    }
  }

  .mobile-menu-toggle {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;

    @media (max-width: 768px) {
      display: block;
    }

    .menu-icon {
      display: block;
      width: 24px;
      height: 2px;
      background-color: $text-color;
      position: relative;
      transition: background-color 0.3s ease;

      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: $text-color;
        transition: transform 0.3s ease;
      }

      &::before {
        top: -8px;
      }

      &::after {
        bottom: -8px;
      }
    }

    &.open {
      .menu-icon {
        background-color: transparent;

        &::before {
          transform: translateY(8px) rotate(45deg);
        }

        &::after {
          transform: translateY(-8px) rotate(-45deg);
        }
      }
    }
  }

  .menu {
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: $background-color;
      padding: 20px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      z-index: 1000;
      flex-direction: column;
      align-items: flex-start;

      &.open {
        display: flex;
      }
    }

    ul {
      list-style: none;
      display: flex;
      margin: 0;
      padding: 0;

      @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
      }

      li {
        margin-left: 30px;

        @media (max-width: 768px) {
          margin-left: 0;
          margin-bottom: 10px;
        }

        a {
          text-decoration: none;
          color: $text-color;
          font-weight: bold;

          &:hover {
            color: $primary-color;
          }
        }
      }
    }

    .apply-button {
      @include button;
      margin-left: 20px;
      text-decoration: none;
      color: white;
      animation: glowing 2.5s infinite; // Add animation back for .apply-button

      @media (max-width: 768px) {
        display: none; // Hide the "Apply Now" button on mobile
      }

      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }
}
