// testimonials.scss
@import 'variables';

.testimonials {
  padding: 80px 0;
  text-align: center;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }

  h2 {
    font-size: 36px;
    margin-bottom: 60px;
    color: $text-color;

    @media (max-width: 768px) {
      font-size: 28px;
      margin-bottom: 40px;
    }
  }

  .testimonial-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 40px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      gap: 20px;
    }

    .testimonial {
      background-color: $accent-color;
      padding: 30px;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

 .testimonial-image {
        width: 100%;
        max-width: 200px;
        height: auto;
        margin: 0 auto 20px;
        border-radius: 8px;
        overflow: hidden;

        img {
          width: 100%;
          height: auto;
          object-fit: cover;
          border-radius: 8px;
        }
      }
      p {
        font-size: 18px;
        margin-bottom: 20px;
        color: $text-color;

        @media (max-width: 768px) {
          font-size: 16px;
          margin-bottom: 15px;
        }
      }

      .author {
        font-weight: bold;
        color: $primary-color;
      }
    }
  }
}
