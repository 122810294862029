// success.scss
.success {
  padding: 20px;
  text-align: center;

  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 20px;
  }

  a {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
  }
}
