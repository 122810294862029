// about.scss
@import 'variables';

.about {
  padding: 100px 0;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }

  h1 {
    font-size: 36px;
    margin-bottom: 40px;
    color: $text-color;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 28px;
      margin-bottom: 30px;
    }
  }

  .about-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .about-text {
      flex: 1;
      padding-right: 50px;

      @media (max-width: 768px) {
        padding-right: 0;
        margin-bottom: 30px;
      }

      p {
        margin-bottom: 20px;
        font-size: 18px;
        color: $text-color;

        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
    }

    .about-image {
      flex: 1;
      text-align: right;

      @media (max-width: 768px) {
        text-align: center;
      }

      img {
        max-width: 100%;
        height: auto;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }
    }
  }
}
