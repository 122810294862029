// faq.scss
@import 'variables';

.faq {
  padding: 80px 0;
  background-color: $background-color;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .faq-title {
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px;
    color: $text-color;
  }

  .faq-content {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    background-color: $accent-color;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
      display: none;
    }
  }

  .faq-list {
    flex: 1;

    .faq-question {
      padding: 20px;
      border-bottom: 1px solid $text-color;
      cursor: pointer;
      transition: background-color 0.3s ease;
      font-weight: bold;

      &:hover {
        background-color: darken($accent-color, 10%);
      }

      &.active {
        background-color: $primary-color;
        color: $background-color;
      }
    }
  }

  .faq-answer-panel {
    flex: 2;
    background-color: $primary-color;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;

    .faq-answer {
      color: $background-color;
      font-weight: bold;
    }

    .faq-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      position: relative;

      img {
        max-width: 300px;
        border-radius: 50%;
        opacity: 0.8;
        transition: transform 0.5s ease-in-out;
        transform-style: preserve-3d; // Add this line
      }

      &:hover img {
        animation: spinCoin 5s infinite linear;
      }

      &:not(:hover) img {
        animation: none; // Add this line
        transform: rotateY(0deg); // Add this line
      }
    }
  }

  .faq-accordion {
    display: none;

    @media (max-width: 768px) {
      display: block;
    }

    .faq-item {
      background-color: $accent-color;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      margin-bottom: 20px;
      cursor: pointer;
      transition: transform 0.3s ease;

      &:hover {
        transform: translateY(-5px);
      }

      &.active {
        .faq-answer {
          max-height: 500px;
          padding: 20px;
          transition: max-height 0.3s ease, padding 0.3s ease;
        }
      }

      .faq-question {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        background-color: $primary-color;
        color: $background-color;
        border-radius: 8px 8px 0 0;

        h3 {
          font-size: 20px;
          font-weight: bold;
        }

        .faq-icon {
          font-size: 18px;
          transition: transform 0.3s ease;
        }
      }

      .faq-answer {
        max-height: 0;
        overflow: hidden;
        padding: 0 20px;
        transition: max-height 0.3s ease, padding 0.3s ease;

        p {
          font-size: 16px;
          color: $text-color;
          line-height: 1.6;
        }
      }
    }
  }
}

@keyframes spinCoin {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}
