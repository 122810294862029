// hero.scss
@import 'variables';
@import 'mixins';

.hero {
  background-color: $accent-color;
  padding: 100px 0;
  border-radius: 16px; // Add this line

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .hero-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
      flex-direction: column;
      text-align: center;
    }
  }

  .hero-text {
    flex: 1;
    padding-right: 50px;

    @media (max-width: 768px) {
      padding-right: 0;
      margin-bottom: 50px;
    }

    h1 {
      font-size: 48px;
      margin-bottom: 20px;
      color: $text-color;
    }

    p {
      font-size: 24px;
      margin-bottom: 30px;
      color: $text-color;
    }

    .cta-button {
      @include button;
      font-size: 18px;
      padding: 12px 30px;
      animation: glowing 2.5s infinite; // Add this line
    }
  }

  .hero-image {
    flex: 1;
    text-align: center;

    img {
      max-width: 100%;
      height: auto;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  }
}
