// Payout.scss
@import 'variables';
@import 'mixins';

.payout {
  padding: 80px 0;
  text-align: center;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .payout-title {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 40px;
    color: $text-color;

    .underline {
      text-decoration: underline;
    }
  }

  .payout-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .payout-image {
    width: 100%;
    max-width: 800px;
    margin-bottom: 40px;
    border-radius: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .apply-button {
  @include button;
    display: inline-block;
    background-color: $primary-color;
    color: white;
    padding: 12px 32px;
    border-radius: 8px;
    font-size: 18px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-decoration: none;
        animation: glowing 2.5s infinite; // Add this line

    &:hover {
      background-color: darken($primary-color, 10%);
    }
  }
}
