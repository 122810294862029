// imageLeftColumnRight.scss
$background-color: #fff;
$purple-color: #4a0e50;
$white-color: #fff;

.image-left-column-right {
  background-color: $background-color;
  padding: 100px 0;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  .image-column {
    width: 100%;
    margin-bottom: 40px;

    @media (min-width: 768px) {
      width: 50%;
      padding-right: 40px;
      margin-bottom: 0;
    }

    .image {
      width: 100%;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      border: 2px solid $purple-color;
    }
  }

  .text-column {
    width: 100%;

    @media (min-width: 768px) {
      width: 50%;
    }

    .flyer-image {
      width: 100%;
      max-width: 400px;
      margin: 0 auto 20px;
      display: block;
      border-radius: 8px; /* Add rounded corners */
    }

    .text-content {
      background-color: $purple-color;
      padding: 40px;
      border-radius: 8px;
      max-width: 400px; /* Set the max-width to match the flyer image */
      margin: 0 auto; /* Center the content */

      .title {
        font-size: 36px;
        font-weight: bold;
        margin-bottom: 24px;
        color: $white-color;
      }

      .description {
        font-size: 18px;
        color: $white-color;
        margin-bottom: 40px;
      }

      .cta-button {
        display: inline-block;
        background-color: $white-color;
        color: $purple-color;
        padding: 12px 32px;
        border-radius: 8px;
        font-size: 18px;
        font-weight: bold;
        border: none;
        cursor: pointer;
        transition: background-color 0.3s ease;
        text-decoration: none;
        animation: glowingWhite 2.5s infinite;

        &:hover {
          background-color: darken($white-color, 10%);
        }
      }
    }
  }
}

@keyframes glowingWhite {
  0% {
    box-shadow: 0 0 5px white;
  }
  50% {
    box-shadow: 0 0 20px white;
  }
  100% {
    box-shadow: 0 0 5px white;
  }
}
