@import 'variables';

.features {
  padding: 80px 20px;
  text-align: center;

  h2 {
    font-size: 36px;
    margin-bottom: 60px;
    color: $text-color;
  }

  .feature-list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 40px;

    .feature {
      flex-basis: 300px;
      padding: 30px;
      background-color: $accent-color;
      border-radius: 8px;
      margin-bottom: 40px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      .icon {
        font-size: 48px;
        color: $primary-color;
        margin-bottom: 20px;
      }

      h3 {
        font-size: 24px;
        margin-bottom: 15px;
        color: $text-color;
      }

      p {
        font-size: 16px;
        color: $text-color;
      }
    }
  }
}
