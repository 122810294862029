// shop.scss
@import 'variables';
@import 'mixins';

.shop {
  padding: 20px;

  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .product-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }

  .product {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 4px;
    text-align: center;

    img {
      width: 100%;
      height: auto;
      max-width: 150px;
      margin: 0 auto 10px;
    }

    h2 {
      font-size: 18px;
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 10px;
      font-size: 14px;
    }

    .buy-button {
      @include button;
      display: inline-block;
      padding: 12px 24px;
      font-size: 16px;
      font-weight: bold;
      text-decoration: none;
      color: white;
      background-color: $primary-color;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      animation: glowing 2.5s infinite;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }
  }
}

.image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  padding: 20px;

  .overlay-content {
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      max-height: 400px;
      object-fit: contain;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
  }
}
