// howItWorks.scss
@import 'variables';

.how-it-works {
  padding: 100px 0;
  background-color: $background-color;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }

  h2 {
    font-size: 36px;
    margin-bottom: 60px;
    color: $text-color;
    text-align: center;
  }

  .steps {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;

    .step {
      flex: 1 1 300px;
      padding: 30px;
      background-color: $accent-color;
      border-radius: 8px;
      text-align: center;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease;

      &:hover {
        transform: translateY(-5px);
      }

      .step-number {
        display: inline-block;
        width: 60px;
        height: 60px;
        line-height: 60px;
        background-color: $primary-color;
        color: $background-color;
        font-size: 24px;
        font-weight: bold;
        border-radius: 50%;
        margin-bottom: 20px;
      }

      h3 {
        font-size: 24px;
        margin-bottom: 15px;
        color: $text-color;
      }

      p {
        font-size: 16px;
        color: $text-color;
      }
    }
  }
}
