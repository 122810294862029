// StartEarning.scss

$black-color: #000;
$white-color: #fff;
$ring-color-1: rgba(74, 14, 80, 0.6);
$ring-color-2: rgba(74, 14, 80, 0.4);
$ring-color-3: rgba(74, 14, 80, 0.2);

.start-earning {
  background-color: $black-color;
  padding: 80px 20px;
  text-align: center;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;

  .rings {
    position: absolute;
    top: 120%;
    left: 100%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;

    .ring {
      position: absolute;
      border-radius: 50%;
      transition: transform 0.3s ease;
    }

    .ring-1 {
      width: 800px;
      height: 800px;
      background-color: $ring-color-1;
      transform: translate(-50%, -50%);
    }

    .ring-2 {
      width: 600px;
      height: 600px;
      background-color: $ring-color-2;
      transform: translate(-50%, -50%);
    }

    .ring-3 {
      width: 400px;
      height: 400px;
      background-color: $ring-color-3;
      transform: translate(-50%, -50%);
    }
  }

  .title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 24px;
    color: $white-color;
    position: relative;
    z-index: 1;
  }

  .description {
    font-size: 18px;
    margin-bottom: 40px;
    color: $white-color;
    position: relative;
    z-index: 1;
  }

.cta-button {
  display: inline-block; // Change from 'block' to 'inline-block'
  background-color: $white-color;
  color: $black-color;
  padding: 12px 32px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
  z-index: 1;
  text-decoration: none; // Add this line to remove the underline
    animation: glowingWhite 2.5s infinite; // Update the animation name

  &:hover {
    background-color: darken($white-color, 10%);
  }
}
}

@keyframes glowingWhite {
  0% {
    box-shadow: 0 0 5px white;
  }
  50% {
    box-shadow: 0 0 20px white;
  }
  100% {
    box-shadow: 0 0 5px white;
  }
}
