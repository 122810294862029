@import 'variables';

.footer {
  position: relative;
  padding: 0;
  border-radius: 16px;
  overflow: hidden;
  height: 400px;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 180px 20px 0; // Increase the top padding to position the content below the wave
    position: relative;
    z-index: 1;
  }

  .footer-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .footer-logo {
    img {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .footer-social {
    display: flex;
    align-items: center;

    @media (max-width: 767px) {
      margin-top: 20px;
    }

    a {
      margin-left: 20px;
      color: white; // Change color to white
      font-size: 40px; // Increase the font size
      transition: color 0.3s ease;

      &:first-child {
        margin-left: 0;
      }

      &:hover {
        color: $text-color; // Change hover color to maintain contrast
      }
    }
  }

  .footer-bottom {
    text-align: center;
    color: white; // Change color to white
    font-size: 14px;
    padding-top: 20px;
    border-top: 1px solid white; // Change border color to white
  }
}

.ocean {
  height: 250px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: $primary-color; // Update the background color to match the wave color
}

.wave {
  background: url('../assets/wave.svg') repeat-x;
  position: absolute;
  top: -140px;
  width: 6400px;
  height: 198px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
  top: -120px; // Adjust the top position of the second wave
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
    swell 7s ease -1.25s infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}
