@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin button {
  background-color: $primary-color;
  text-decoration: none;
  color: white;
  padding: 12px 32px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: darken($primary-color, 10%);
  }
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 5px $primary-color;
  }
  50% {
    box-shadow: 0 0 20px $primary-color;
  }
  100% {
    box-shadow: 0 0 5px $primary-color;
  }
}
